import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import 'moment/locale/it';
import React, { useState } from 'react';
import { borderRadius } from '../../classes';
import useColor from '../../context/themeContext';
import BorderTextField from '../StyledComponent/BorderTextField';
import FromDate from './ExportFilter/FromDate';
import MultipleSelect from './ExportFilter/MultipleSelect';
import ResetFilter from './ExportFilter/ResetFilter';
import { SyncExport } from './Utilsfunctions';
moment.locale('it')

const SubscriptionExport = (props) => {
  
  const { COLORS } = useColor()

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: "28px",
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: "0 20px"
    },
    buttonSuccess: {
      backgroundColor: COLORS.primary,
      '&:hover': {
        backgroundColor: COLORS.primary,
      },
    },
    buttonProgress: {
      color: "#fff",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    input: {
      padding: "12px 20px"
    }
  }));
  const classes = useStyles();

  const [input, setInput] = useState({});
  const [sub, setSub] = useState("");
  const [completeSub, setCompleteSub] = useState(false);

  const handleChangeDate = (name, date) => {
    setInput({ ...input, [name]: date })
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (Array.isArray(value)) {
      if (!value?.find(el => el === "ALL")) {
        setInput({ ...input, [name]: value })
      } else {
        return;
      }
    } else {
      setInput({ ...input, [name]: value })
    }
  }

  const resetChange = (name) => {
    setInput({ ...input, [name]: [] })
  }
  const disableDate = (name) => {
    setInput({ ...input, [name]: "" })
  }

  const exportSub = () => {

    props.setLoadingSub(true);
    let data;
    if (props.userMe?.role?.tier === "BO_COM") {
      data = {
        annotation: input.annotation,
        audit_filters: {
          "filters": []
        },
        filters: {
          "groups_ids": cleanIds(input?.groups_ids),
          "courses_ids": cleanIds(input?.courses_ids),
          "roles_ids": cleanIds(input?.roles_ids),
          "from_date": input?.from_date,
          "from_completion_date": input?.from_date,
          "from_last_access_date": input?.from_date,
          "from_first_access_date": input?.from_date,
        }
      }
    } else {
      data = {
        annotation: input.annotation,
        audit_filters: {
          "filters": []
        },
        filters: {
          "groups_ids": [2],
          "courses_ids": cleanIds(input.courses_ids),
          "from_date": input?.from_date,
          "from_completion_date": input?.from_date,
          "from_last_access_date": input?.from_date,
          "from_first_access_date": input?.from_date,
        }
      }
    }

    if (input?.groups_ids?.length > 0) {
      let groups_name = "";
      for (let i in input?.groups_ids) {
        const g = props.groups.find(group => group.id === input?.groups_ids[i].id);
        groups_name += g.name + ", "
      }
      data.audit_filters.filters.push({ "value": groups_name, "label": "Filtrato per gruppi: " })
    }
    if (input?.roles_ids?.length > 0) {
      let roles_name = "";
      for (let i in input?.roles_ids) {
        const r = props.roles.find(role => role.id === input?.roles_ids[i].id);
        roles_name += r.name + ", "
      }
      data.audit_filters.filters.push({ "value": roles_name, "label": "Filtrato per ruoli: " })
    }
    if (input?.courses_ids?.length > 0) {
      let courses_name = "";
      for (let i in input?.courses_ids) {
        const c = props.courses.find(course => course.id === input?.courses_ids[i].id);
        courses_name += c.name + ", "
      }
      data.audit_filters.filters.push({ "value": courses_name, "label": "Filtrato per corsi: " })
    }

    clean(data.filters);

    try{
      if (data.filters) {

        let and_filters = []
        let or_filters = []

        for (let key in data.filters) {
          if (["groups_ids", "courses_ids", "roles_ids"].includes(key)) {

            //Mapping django keys
            if (key==="courses_ids"){
              and_filters.push("course__pk__in")
            }else if(key==="groups_ids"){
              and_filters.push("user__groups__pk__in")
            }else{
              and_filters.push("user__role__pk__in")
            }

          }else if ([
            "from_date", "from_last_access_date",
            "from_first_access_date", "from_completion_date",
          ].includes(key)){
  
              if(input?.from_date_filters)
                if (!input.from_date_filters.some(df => df === "ALL")){
                  if(!input.from_date_filters.some(df => df?.value === key)){
                    continue
                  }
                }
              

              //Mapping django keys
              if (key==="from_date"){

                or_filters.push("created__gte")
              }else if(key==="from_last_access_date"){

                or_filters.push("last_access_date__gte")
              }else if(key==="from_first_access_date"){

                or_filters.push("first_access_date__gte")
              }else{

                or_filters.push("completion_date__gte")
              }

          }
        }

        data.filters["logic"] = {
            type: "and",
            filters:[
              {
                "fields": or_filters,
                "type": "or"
              },
              {
                "fields": and_filters,
                "type": "and"
              }
            ]
          }
      }
    }catch(e){
      console.error(e)
    }
    console.log(data)
    SyncExport(`/custom/v1/exports/subscriptions/`, data,
      setSub, props.setCustomAlert, setCompleteSub, props.setLoadingSub, props.setReloadSub);
  }

  function cleanIds(array) {
    if (Array.isArray(array)) {
      return array.map(a => a.id);
    } else {
      return array;
    }
  }


  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "NO" || obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  return (
    <Paper elevation={2} style={{ minHeight: "100%", borderRadius: borderRadius, padding: "50px 20px" }}>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <p style={{ color: COLORS.primary, paddingLeft: "20px" }} className={"label-attivita"}>Report utenti/corsi</p>
        <InputLabel style={{ padding: "20px", margin: "10px auto 15px", fontSize: "20px" }}>
          Scarica il report aggiornato sullo stato delle iscrizioni degli utenti ai corsi
        </InputLabel>

        <Grid spacing={3} item container style={{ marginBottom: 10 }}>

          <ResetFilter
            setInput={setInput}
            input={input}
          />

          <Grid item xs={12} md={12} xl={12} style={{ textAlign: "center" }}>
            <InputLabel style={{ margin: "0 auto", fontSize: "17px" }}>
              Etichetta export:
            </InputLabel>
            <BorderTextField
              variant="outlined"
              name="annotation"
              onChange={handleChange}
              value={input?.annotation || ""}
              inputProps={{ className: classes.input }}
            />
          </Grid>

          <MultipleSelect
            name={"courses_ids"}
            all_label={"Tutti i corsi"}
            label={"Filtra per corsi:"}
            data={props.courses}
            handleChange={handleChange}
            resetChange={resetChange}
            input={input?.courses_ids}
          />


          {props.userMe?.role?.tier === "BO_COM" ?
            <React.Fragment>
              <MultipleSelect
                name={"groups_ids"}
                label={"Filtra per gruppi:"}
                all_label={"Tutti i gruppi"}
                data={props.groups}
                handleChange={handleChange}
                resetChange={resetChange}
                input={input?.groups_ids}
              />


              <MultipleSelect
                data={props.roles}
                name={"roles_ids"}
                all_label={"Tutti i ruoli"}
                label={"Filtra per ruoli: "}
                handleChange={handleChange}
                resetChange={resetChange}
                input={input?.roles_ids}
              />
            </React.Fragment>
            : null}

        </Grid>

        <hr color="#cccccc"/>
        <Grid spacing={3} item container style={{ marginTop: 20, marginBottom: 20 }}>
            <FromDate
              export
              label={
                <span>
                  Genera dal:
                  <CustomTooltip
                    title="I risultati includeranno gli elementi che soddisfano almeno una delle condizioni di filtro sulle date. Nella prossima select sarà possibile selezionare su quale tipologia di data applicare il filtro."
                  >
                    <InfoIcon style={{ fontSize: "19px" }} />
                  </CustomTooltip>
              </span>
              }
              value={input.from_date}
              handleChange={(date) => handleChangeDate("from_date", date)}
              disableDate={()=>disableDate("from_date")}
            />

            <MultipleSelect
              name={"from_date_filters"}
              label={"Applica filtro per:"}
              all_label={"Applica per tutte le date"}
              data={props.date_filters}
              handleChange={handleChange}
              resetChange={resetChange}
              input={input?.from_date_filters}
            />
        </Grid>

        <div className={classes.wrapper}>
          {!completeSub ?
            <Button
              style={{ padding: "10px 5px", borderRadius: "20px", background: COLORS.primary, width: "200px" }}
              variant="contained"
              color="primary"
              disabled={(!props.loadingSurvey && !props.loadingSub && !props.loadingSession) ? false : true}
              onClick={exportSub}
            >
              Genera CSV
              </Button>
            :
            <ButtonGroup color="primary" style={{ borderRadius: "20px" }} variant="contained" >
              <Button
                style={{ padding: "10px 15px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", background: "#3aac4d" }}
                startIcon={<GetAppIcon />}
                disabled={(props.loadingSub || props.loadingSurvey) || props.loadingSession}
                onClick={() => {
                  setCompleteSub(false);
                }}
                download
                href={sub}
              >
                Scarica CSV
                </Button>
              <Button
                style={{ padding: "10px 15px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px", background: COLORS.primary }}
                onClick={() => {
                  setCompleteSub(false);
                }}
              >
                Annulla
                </Button>
            </ButtonGroup>
          }
          {(props.loadingSurvey || props.loadingSession) &&
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomTooltip className={classes.tooltip} title="Attendere il completamento delle altre operazioni per utilizzare il servizio" placement="top-end">
                <InfoIcon style={{ marginLeft: "10px", color: COLORS.primary }} />
              </CustomTooltip>
            </div>
          }
          {props.loadingSub && <CircularProgress size={24} className={classes.buttonProgress} />}

        </div>
        {props.loadingSub && <p style={{ paddingTop: "15px", color: COLORS.primary, fontSize: "15px", textAlign: "center" }}>Si prega di attendere, l'operazione potrebbe richiedere alcuni minuti</p>}
      </div>
    </Paper>
  )
}

export default SubscriptionExport;